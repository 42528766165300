import { MouseEventHandler } from "react";

export const IconBack = ({
	onClick,
}: {
	onClick: MouseEventHandler;
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			className="cursor-pointer"
			onClick={onClick}
		>
			<path
				d="M11.4009 6.27303C11.4904 6.35927 11.5614 6.46175 11.6098 6.57459C11.6583 6.68742 11.6832 6.8084 11.6832 6.93057C11.6832 7.05275 11.6583 7.17372 11.6098 7.28656C11.5614 7.3994 11.4904 7.50187 11.4009 7.58812L7.28251 11.5713L19.0402 11.5713C19.2947 11.5713 19.5389 11.6691 19.7189 11.8432C19.8989 12.0172 20 12.2534 20 12.4996C20 12.7458 19.8989 12.9819 19.7189 13.156C19.5389 13.3301 19.2947 13.4279 19.0402 13.4279L7.28251 13.4279L11.4009 17.4126C11.5812 17.5869 11.6825 17.8235 11.6825 18.0701C11.6825 18.3167 11.5812 18.5532 11.4009 18.7276C11.2206 18.902 10.976 19 10.721 19C10.466 19 10.2215 18.902 10.0412 18.7276L4.2823 13.1579C4.19282 13.0716 4.12182 12.9692 4.07338 12.8563C4.02494 12.7435 4 12.6225 4 12.5003C4 12.3782 4.02494 12.2572 4.07338 12.1443C4.12182 12.0315 4.19282 11.929 4.2823 11.8428L10.0412 6.27303C10.1303 6.18649 10.2363 6.11783 10.353 6.07097C10.4696 6.02412 10.5947 6 10.721 6C10.8474 6 10.9724 6.02412 11.0891 6.07097C11.2058 6.11783 11.3117 6.18649 11.4009 6.27303Z"
				fill="#F1F1F1"
			/>
		</svg>
	);
};

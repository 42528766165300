export const ArrowBack = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
		>
			<g clipPath="url(#clip0_257_3101)">
				<path
					d="M17.67 4.37L15.9 2.6L6 12.5L15.9 22.4L17.67 20.63L9.54 12.5L17.67 4.37Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_257_3101">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const HelpIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			className="cursor-pointer"
		>
			<g clipPath="url(#clip0_257_3159)">
				<path
					d="M10.0003 1.66666C5.40033 1.66666 1.66699 5.39999 1.66699 9.99999C1.66699 14.6 5.40033 18.3333 10.0003 18.3333C14.6003 18.3333 18.3337 14.6 18.3337 9.99999C18.3337 5.39999 14.6003 1.66666 10.0003 1.66666ZM10.8337 15.8333H9.16699V14.1667H10.8337V15.8333ZM12.5587 9.37499L11.8087 10.1417C11.2087 10.75 10.8337 11.25 10.8337 12.5H9.16699V12.0833C9.16699 11.1667 9.54199 10.3333 10.142 9.72499L11.1753 8.67499C11.4837 8.37499 11.667 7.95832 11.667 7.49999C11.667 6.58332 10.917 5.83332 10.0003 5.83332C9.08366 5.83332 8.33366 6.58332 8.33366 7.49999H6.66699C6.66699 5.65832 8.15866 4.16666 10.0003 4.16666C11.842 4.16666 13.3337 5.65832 13.3337 7.49999C13.3337 8.23332 13.0337 8.89999 12.5587 9.37499Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_257_3159">
					<rect
						width="20"
						height="20"
						fill="white"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export const FailedCircle = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100"
			height="100"
			viewBox="0 0 100 100"
			fill="none"
		>
			<path
				d="M50 4.6875C37.8906 4.6875 26.4844 9.375 17.9688 17.9688C9.14062 26.7969 4.6875 38.3594 4.6875 50C4.6875 61.6406 9.14062 73.2031 17.9688 82.0312C26.5625 90.625 37.8906 95.3125 50 95.3125C62.1094 95.3125 73.5156 90.625 82.0312 82.0312C99.6875 64.375 99.6875 35.625 82.0312 17.9688C73.5156 9.375 62.1094 4.6875 50 4.6875ZM78.75 78.75C71.0156 86.4062 60.8594 90.625 50 90.625C39.1406 90.625 28.9844 86.4062 21.25 78.75C5.46875 62.8906 5.46875 37.1094 21.25 21.25C28.9844 13.5938 39.1406 9.375 50 9.375C60.8594 9.375 71.0156 13.5938 78.75 21.25C86.6406 29.2188 90.625 39.6094 90.625 50C90.625 60.3906 86.6406 70.7812 78.75 78.75Z"
				fill="#FF3B6A"
			/>
			<path
				d="M64 38.82L61.18 36L50 47.18L38.82 36L36 38.82L47.18 50L36 61.18L38.82 64L50 52.82L61.18 64L64 61.18L52.82 50L64 38.82Z"
				fill="#FF3B6A"
			/>
		</svg>
	);
};

export const InboxIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100"
			height="100"
			viewBox="0 0 100 100"
			fill="none"
		>
			<path
				d="M99.6516 46.8312L99.6826 46.8119L78.8492 13.4785C78.4707 12.8701 77.8035 12.5 77.0832 12.5H22.9166C22.1963 12.5 21.5291 12.8703 21.1506 13.4785L0.317383 46.8119L0.348438 46.8312C0.14707 47.152 0 47.5096 0 47.9166V79.1666C0 83.7627 3.73945 87.5 8.3334 87.5H91.6668C96.2606 87.5 100 83.7627 100 79.1666V47.9166C100 47.5096 99.8529 47.152 99.6516 46.8312ZM24.0723 16.6666H75.9277L94.1582 45.8332H79.1668C74.5729 45.8332 70.8334 49.5705 70.8334 54.1666C70.8334 56.4635 68.9656 58.3332 66.6668 58.3332H33.3334C31.0344 58.3332 29.1668 56.4635 29.1668 54.1666C29.1668 49.5707 25.4273 45.8332 20.8334 45.8332H5.8418L24.0723 16.6666ZM95.8334 79.1666C95.8334 81.4635 93.9656 83.3332 91.6668 83.3332H8.3334C6.03438 83.3332 4.1668 81.4635 4.1668 79.1666V50H20.8334C23.1324 50 25 51.8697 25 54.1666C25 58.7627 28.7395 62.5 33.3334 62.5H66.6668C71.2606 62.5 75 58.7627 75 54.1666C75 51.8697 76.8678 50 79.1666 50H95.8332V79.1666H95.8334Z"
				fill="white"
				fillOpacity="0.1"
			/>
		</svg>
	);
};

export const CheckCircle = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100"
			height="100"
			viewBox="0 0 100 100"
			fill="none"
		>
			<path
				d="M50 4.6875C37.8906 4.6875 26.4844 9.375 17.9688 17.9688C9.14062 26.7969 4.6875 38.3594 4.6875 50C4.6875 61.6406 9.14062 73.2031 17.9688 82.0312C26.5625 90.625 37.8906 95.3125 50 95.3125C62.1094 95.3125 73.5156 90.625 82.0312 82.0312C99.6875 64.375 99.6875 35.625 82.0312 17.9688C73.5156 9.375 62.1094 4.6875 50 4.6875ZM78.75 78.75C71.0156 86.4062 60.8594 90.625 50 90.625C39.1406 90.625 28.9844 86.4062 21.25 78.75C5.46875 62.8906 5.46875 37.1094 21.25 21.25C28.9844 13.5938 39.1406 9.375 50 9.375C60.8594 9.375 71.0156 13.5938 78.75 21.25C86.6406 29.2188 90.625 39.6094 90.625 50C90.625 60.3906 86.6406 70.7812 78.75 78.75Z"
				fill="#27E39F"
			/>
			<path
				d="M51.6406 32.7344C50.7031 31.7969 49.2188 31.7969 48.3594 32.7344L36.6406 44.4531C36.1719 44.9219 35.9375 45.4687 35.9375 46.0937C35.9375 46.7187 36.1719 47.2656 36.6406 47.7344C37.5781 48.6719 39.0625 48.6719 39.9219 47.7344L47.6562 40V64.0625C47.6562 65.3906 48.6719 66.4062 50 66.4062C51.3281 66.4062 52.3438 65.3906 52.3438 64.0625V40L60.0781 47.7344C61.0156 48.6719 62.5 48.6719 63.3594 47.7344C64.2969 46.7969 64.2969 45.3125 63.3594 44.4531L51.6406 32.7344Z"
				fill="#27E39F"
			/>
		</svg>
	);
};

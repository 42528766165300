export const CONTEXT_ACTIONS = {
  SET_WALLET_CONNECTED: "SET_WALLET_CONNECTED",
};

export const WALLET_TYPES = {
  EVM: "evm",
  STARKNET: "starknet",
};

export const WALLETS = {
  METAMASK: "metamask",
  ARGENT_X: "argentX",
  BRAAVOS: "braavos",
  OKX: "okx",
};
